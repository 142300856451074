import React, {Component} from "react";
import logo from './logom.jpg';

export class Header extends Component {
    render() {
        return <header
            id="header"
            className="animated slideInDown"
            style={{animationDelay: "1.8s"}}
        >

            <div className="container_header">
                <img id="mobile_image" style={{borderRadius: '12%'}} src={logo}/>
                <p id="logo">Berlyn Cabrera</p>
                <div className="header_labels navigation">
                    <a href="#work">work</a>
                    <a href="#bio">about</a>
                    <a href>blog</a>
                    <a href="#contact">contact</a>
                </div>
            </div>
            {/*<table>*/}
            {/*    <tbody>*/}
            {/*    <tr>*/}
            {/*        <td id="mobile_image" ><img style={{borderRadius:'12%'}} src={logo} /></td>*/}
            {/*        <td id="logo">Berlyn Cabrera</td>*/}
            {/*        <td id="navigation">*/}
            {/*            <a href="#work">work</a>*/}
            {/*            <a href="#bio">about</a>*/}
            {/*            <a href>blog</a>*/}
            {/*            <a href="#contact">contact</a>*/}
            {/*        </td>*/}
            {/*    </tr>*/}
            {/*    </tbody>*/}
            {/*</table>*/}
        </header>;
    }
}