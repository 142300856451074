import React, {Component} from "react";

export class SocialInformation extends Component {
    render() {
        return <table>
            <tbody>
            <tr>
                <td
                    className="animated zoomIn"
                    style={{animationDelay: "2.2s"}}
                >
                    <a className="social">
                        <i className="fab fa-facebook"/>
                    </a>
                </td>
                <td
                    className="animated zoomIn"
                    style={{animationDelay: "2.4s"}}
                >
                    <a className="social">
                        <i className="fab fa-twitter"/>
                    </a>
                </td>
                <td
                    className="animated zoomIn"
                    style={{animationDelay: "2.6s"}}
                >
                    <a className="social">
                        <i className="fab fa-instagram"/>
                    </a>
                </td>
                <td
                    className="animated zoomIn"
                    style={{animationDelay: "2.8s"}}
                >
                    <a className="social">
                        <i className="fab fa-dribbble"/>
                    </a>
                </td>
                <td
                    className="animated zoomIn"
                    style={{animationDelay: "3.0s"}}
                >
                    <a className="social">
                        <i className="fab fa-medium"/>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>;
    }
}