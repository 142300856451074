import React, {Component} from "react";
import * as emailjs from 'emailjs-com'

export class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: ""
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    clearForm = () => {
        this.setState({
            name: "",
            email: "",
            message: ""
        });
    }

    handleSubmit = (event) => {
        let templateParams = {
            from_name: this.state.name + `( "+"${this.state.email})`,
            to_name: 'berlynjoycabrera86@gmail.com',
            subject: "Hire You!!!",
            message_html: this.state.message,
        }
        const service_id = "contact_service";
        const template_id = "template_VMZsfrAd";
        const user_id = "user_WoIxxgIjMDfYnroBNUdTq";
        emailjs.send(service_id, template_id, templateParams, user_id).then(it => {
            this.clearForm()
            event.preventDefault()
        });

    }

    render() {

        return (
            <div id="contact">
                <h1>contact</h1>
                <table>
                    <tbody>
                    <tr>
                        <td>
                            <div id="inner_div">
                                <table id="inner_table">
                                    <tbody>
                                    <tr>
                                        <td>
                                            <i className="fas fa-phone"/> &nbsp; +971 50 763 8955
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <i className="fas fa-at"/> &nbsp; berlynjoycabrera86@gmail.com
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <i className="fas fa-fax"/> &nbsp; +971 50 763 8955
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <i className="fas fa-map-marker-alt"/>
                                            <div id="address">
                                                Shakhbout Bin Sultan St ,
                                                <br/>
                                                Abu Dhabi ,
                                                <br/>
                                                United Arab Emirates
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a className="social" href="https://www.facebook.com/berlynjoy.cabrera"
                                               target="_blank">
                                                <i className="fab fa-facebook"/>
                                            </a>
                                            {/*<a className="social">*/}
                                            {/*    <i className="fab fa-twitter"/>*/}
                                            {/*</a>*/}
                                            {/*<a className="social">*/}
                                            {/*    <i className="fab fa-instagram"/>*/}
                                            {/*</a>*/}
                                            {/*<a className="social">*/}
                                            {/*    <i className="fab fa-dribbble"/>*/}
                                            {/*</a>*/}
                                            <a className="social"
                                               href="https://www.linkedin.com/in/berlyn-joy-cabrera-874b71ab"
                                               target="_blank">
                                                <i className="fab fa-linkedin"/>
                                            </a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                        <td>
                            <form onSubmit={this.handleSubmit}>
                                <input type="text" placeholder="name" required
                                       name="name"
                                       type="string"
                                       onChange={this.handleInputChange}
                                />
                                <input type="email" placeholder="email" required
                                       name="email"
                                       type="string"
                                       onChange={this.handleInputChange}
                                />
                                <br/>
                                <textarea
                                    placeholder="your message"
                                    name="message"
                                    type="string"
                                    required
                                    rows={5}
                                    defaultValue={""}
                                    onChange={this.handleInputChange}
                                />
                                <br/>
                                <button className="btn_one">send</button>
                            </form>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
