import React, {Component} from "react";

export class Bio extends Component {
    render() {
        return (
            <div id="bio">
                <h1>about</h1>
                <p>
                    I've worked in the food industry for the past 10 years. Working in the food industry was an
                    eye-opener for me. I understood that the customer is always the priority. You do your best to keep
                    them happy, so that they come back again. Also, another thing, is that to survive in the industry,
                    you need to be quick, accurate and approachable. Get the orders, and deliver, quickly and correctly.
                </p>
                <p>
                    <h2>EDUCATION</h2>

                    <p> Don Mariano Marcos Memorial State University (MLUC),
                        San Fernando City, La Union Philippines
                        B.S. in Hotel and Restaurant Management
                        2003 - 2008</p>
                    <p> Sea and Sky College,
                        San Fernando City, La Union Philippines
                        (Bartending)
                        May - June2009</p>

                    <p>Asiaworld Culinary Academy,
                        San Fernando City, La Union Philippines
                        (Basic Culinary Arts NC II)
                        March - October 2009</p>


                </p>
            </div>
        );
    }
}
