import React, {Component} from "react";
import {SocialInformation} from "./SocialInformation";

export class SideInfoDetails extends Component {
    goto = (url) => window.location = url;
    render() {
        return (
            <div className="main_side_container">
                <div className="side_container" style={{animationDelay: "2s"}}>
                    <h1 className="tag_info">“There are people in the world so hungry, that God cannot appear to them
                        except in the form of
                        bread.”
                        ― Mahatma Gandhi</h1>
                    <a className="btn_one" href="#contact">Hire Me</a>
                </div>
                <div
                    id="rightImage"
                    className="animated jackInTheBox"
                    style={{animationDelay: "2.2s"}}
                />
            </div>
            // <table id="top_part">
            //   <tbody>
            //   <tr>
            //     <td
            //         id="about"
            //         className="animated slideInLeft"
            //         style={{animationDelay: "2s"}}
            //     >
            //       <h1 id="tag_info">“There are people in the world so hungry, that God cannot appear to them except in the form of bread.”
            //         ― Mahatma Gandhi</h1>
            //       <button className="btn_one">Hire Me</button>
            //       <br/>
            //       {/*<SocialInformation/>*/}
            //     </td>
            //     <td
            //         id="rightImage"
            //         className="animated jackInTheBox"
            //         style={{animationDelay: "2.2s"}}
            //     />
            //   </tr>
            //   </tbody>
            // </table>
        );
    }
}
