import React, {Component} from "react";
import "./App.css";
import {Header} from "./Header";
import {SideInfoDetails} from "./SideInfoDetails";
import {Work} from "./Work";
import {Bio} from "./Bio";
import {Contact} from "./Contact";

class App extends Component {
    render() {
        return (
            <div>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <meta httpEquiv="X-UA-Compatible" content="ie=edge"/>
                <title>Berlyn Cabrera</title>
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css"
                />
                <link
                    rel="stylesheet"
                    href="https://use.fontawesome.com/releases/v5.7.1/css/all.css"
                    integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
                    crossOrigin="anonymous"
                />
                <link rel="stylesheet" href="index.css"/>

                <div id="magnify">
                    <h1 onclick="closemagnify()">
                        <i className="fas fa-times"/>
                    </h1>
                    <div id="img_here"/>
                </div>
                <div className="main_container">
                    <Header/>
                    <SideInfoDetails/>
                    <Work/>
                    <Bio/>
                    <Contact/>
                    <div id="footer">
                        Made by Pradeep Negi <br/>{" "}

                    </div>
                </div>
            </div>
        );
    }
}

export default App;
